iframe.map {
    width: 100%;
    height: 100vh;
    border: none;
}

@media (max-width: 1200px) {
    iframe.map {
        height: calc(100vh - 64px);
    }
}